/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import "./Empresa.scss";
import ScrollspyNav from "react-scrollspy-nav";
import InformationModal from "../../components/partials/modals/InformationModal";
import $ from "jquery";
import SelectProductModal from "../../components/partials/modals/SelectProductModal";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getOrganizationMenu, getSections, updateStatusStoreInMenu, getProductTamanho } from "../../components/helpers/Api";
import OrdersList from "./orders/orders-list/OrdersList";
import { Load } from "../../components/widgets/spinner/Spinner"
import {
  updateStatusStore,
} from "../../components/helpers/Api";

const Empresa = () => {
  const [openInformationModal, setOpenInformationModal] = useState(false);
  const [product, setProduct] = useState("");
  const [hiddenNextButton, setHiddenNextButton] = useState(false);
  const [hiddenPreviousButton, setHiddenPreviousButton] = useState(true);
  const [lastIndexPosition, setLastIndexPosition] = useState();
  const [firstIndexPosition, setFirstIndexPosition] = useState();
  const [categories, setCategories] = useState();
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [orgData, setOrgData] = useState({});
  const [searchProduct, setSearchProduct] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const [openOrderList, setOpenOrderList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(true)
  const [closeHour, setCloseHour] = useState(0)
  const [closeMinute, setCloseMinute] = useState("")
  const [openeHour, setOpenHour] = useState(0)
  const [openeMinute, setOpenMinute] = useState("")
  const [selectedProduct, setSelectedProduct] = useState({});

  const { empresa } = useParams();
  const history = useNavigate();

  useEffect(() => {
    handleGetSections();
    handleGetDataMenu();
  }, [empresa]);

  const arrayId = [];

  function handleOrderView() {
    history(`/menu/${empresa}/pedidos`);
  }

  function handleGetDataMenu() {
    setLoading(true);
    getOrganizationMenu(empresa)
      .then((res) => {
        setOrgData(res.data.data);
        localStorage.setItem("minimum_price", res.data.data.preco_minimo);
        localStorage.setItem("cod_org", res.data.data.cod_org);

        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();

        const funcionamento = res.data.data?.funcionamento

        const today = new Date().toLocaleDateString("en-US", { weekday: "long" }).toLowerCase();
        const dayManager = funcionamento.dias[today] || null;
        const [openHour, openMinute] = dayManager.open.split(":").map(Number);
        const [closeHour, closeMinute] = dayManager.close.split(":").map(Number);

        const openMinuteStr = String(openMinute).padStart(2, '0');
        const closeMinuteStr = String(closeMinute).padStart(2, '0');
        setOpenHour(openHour);
        setOpenMinute(openMinuteStr);
        setCloseHour(closeHour);
        setCloseMinute(closeMinuteStr);

        const toMinutes = (hours, minutes) => hours * 60 + minutes;
        const currentTotalMinutes = toMinutes(currentHours, currentMinutes);
        const openingTotalMinutes = toMinutes(openHour, openMinute);
        const closingTotalMinutes = toMinutes(closeHour, closeMinute);
        console.log(dayManager.open)
        if (!dayManager.disabled) {

          if (currentTotalMinutes >= openingTotalMinutes && currentTotalMinutes <= closingTotalMinutes) {

            if (status === true) {
              handleStatusStore(true);
            }
          } else {
            if (status !== false) {

              handleStatusStore(false);
            }
          }
        } else {
          handleStatusStore(false)
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handleGetSections() {
    setLoading(true);

    getSections(empresa)
      .then(async (res) => {
        let initCat = res.data.data;

        await Promise.all(
          initCat.map(async (item) => {
            arrayId.push(item.cod_secao);
            await Promise.all(
              item.tb_produtos.map(async (prod) => {
                let tamanhos = await getMinusPrice(prod.cod_produto);
                if (tamanhos.length > 0) {
                  prod["preco_venda_vista"] = tamanhos[0].valor;
                }
              })
            );
          })
        );

        setCategories(initCat);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

  }

  async function getMinusPrice(id) {
    if (!id) return [];
    try {
      const response = await getProductTamanho(id);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  function handleGetTamanhos(id) {
    if (id) {
      getProductTamanho(id)
        .then((res) => {
          return res.data
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return []
    }
  }

  function handlePreviousButton(data) {
    let leftPos = $("#nav-bar ul").scrollLeft();

    $("#nav-bar ul").animate({ scrollLeft: leftPos - 250 }, data ? 150 : 350);
    setHiddenNextButton(false);
    let first = $(`a[href^="#${arrayId[0]}"]`).offset().left;
    setFirstIndexPosition(first);
    if (first === firstIndexPosition) {
      setHiddenPreviousButton(true);
    }
  }
  function handleNextButton() {
    let leftPos = $("#nav-bar ul").scrollLeft();
    $("#nav-bar ul").animate({ scrollLeft: leftPos + 250 }, 350);
    setHiddenPreviousButton(false);
    let last = $(`a[href^="#${arrayId[arrayId.length - 1]}"]`).offset().left;
    setLastIndexPosition(last);
    if (last === lastIndexPosition) {
      setHiddenNextButton(true);
    }
  }
  var lastScrollTop = 0;
  $(document).ready(() => {
    $(window).scroll(function (event) {
      if ($(".is-active")[0]) {
        let targetOffset = $(".is-active").offset().left;
        let ulWidth = $("#nav-bar ul")[0].clientWidth;
        let activeWidth = $(".is-active")[0].clientWidth;

        var st = $(this).scrollTop();
        let leftPos = $("#nav-bar ul").scrollLeft();

        if (st > lastScrollTop) {
          if (targetOffset > ulWidth * 0.8) {
            $("#nav-bar ul").animate({ scrollLeft: leftPos + activeWidth }, 1);
          }
        } else {
          if (targetOffset < 131) {
            $("#nav-bar ul").animate(
              { scrollLeft: leftPos - $(".is-active")[0].clientWidth },
              1
            );
          }
        }
        lastScrollTop = st;
      }
    });
  });

  function handleStatusStore(status) {
    let data = {
      status,
    };
    updateStatusStoreInMenu(data)
      .then((res) => {
        setStatus(res.data.data.status);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => { });
  }


  return (
    <>
      {openOrderList ? (
        <OrdersList
          open={openOrderList}
          setFalse={() => setOpenOrderList(false)}
        />
      ) : (
        <div className="establishment">
          <div className="top-bar">
            <div className="content wrapper">
              {openSearch ? (
                <i
                  onClick={() => setOpenSearch(false)}
                  className="search-button fa-solid fa-xmark text-danger fs-4"
                ></i>
              ) : (
                <i
                  onClick={() => setOpenSearch(true)}
                  className="search-button fa-solid fa-magnifying-glass"
                ></i>
              )}
              <input
                type="text"
                placeholder="Digite o que procura"
                className="input"
                value={searchProduct}
                onChange={(e) => setSearchProduct(e.target.value)}
              />
              {!openSearch ? (
                <div className="box-button">
                  <button onClick={() => handleOrderView()}>Pedidos</button>
                  <button
                    onClick={() => [history(`/menu/${empresa}/carrinho`)]}
                  >
                    <i class="fa-solid fa-cart-shopping"></i>
                    <span style={{ marginLeft: "5px", marginRight: "1px" }}>
                      Carrinho
                    </span>
                    <span>
                      (
                      {localStorage.getItem("cart")
                        ? JSON.parse(localStorage.getItem("cart")).length
                        : 0}
                      )
                    </span>
                  </button>
                </div>
              ) : (
                <input
                  type="text"
                  placeholder="Digite o que procura"
                  className="input"
                  value={searchProduct}
                  onChange={(e) => setSearchProduct(e.target.value)}
                  style={{ display: "block" }}
                />
              )}
            </div>
          </div>
          <header
            crossOrigin="anonymous"
            style={{
              backgroundImage: `url(${orgData.capa
                ? process.env.REACT_APP_API_URL + `/${orgData.capa}`
                : ""
                })`,
              backgroundPosition: "center center", // Centraliza a imagem
              backgroundSize: "cover", // Faz com que a imagem cubra todo o header
              backgroundRepeat: "no-repeat", // Evita repetição da imagem
              height: "450px", // Define a altura do header
              width: "100%", // Largura total
              display: "flex", // Adiciona flexbox para alinhar o conteúdo
              alignItems: "center", // Alinha verticalmente
              justifyContent: "center", // Alinha horizontalmente
              color: "#fff", // Contraste do texto
              textShadow: "1px 1px 4px rgba(0, 0, 0, 0.7)", // Adiciona contraste ao texto
            }}
          >

            {loading ? (
              ""
            ) : (
              <div className="content">
                <img
                  loading="lazy"
                  crossOrigin="anonymous"
                  className="logo"
                  height={100}
                  width={100}
                  src={
                    orgData.logo
                      ? process.env.REACT_APP_API_URL + `/${orgData.logo}`
                      : "/img/image.png"
                  }
                  alt=""
                />
                <p className="title">{orgData.nome}</p>
                <address className="address">
                  <i className="fa-solid fa-location-dot"></i>
                  <span>{orgData.logradouro} {orgData.numero},{orgData.bairro} - {orgData.cidade},,</span>
                </address>
                {orgData.status ? (
                  <div className="box-open-closed">
                    <p className="open button">
                      <span>Aberto até às {closeHour}:{closeMinute} </span>
                    </p>
                  </div>
                ) : (
                  <div className="box-open-closed">
                    <p className="closed button">
                      <span>Abre às {openeHour}:{openeMinute} </span>
                    </p>
                  </div>
                )}
                <div className="box-open-closed">
                  <button
                    onClick={() => setOpenInformationModal(true)}
                    className="button"
                  >
                    Mais informações
                  </button>
                </div>
              </div>
            )}
          </header>
          <nav className="" id="nav-bar">
            <ScrollspyNav
              scrollTargetIds={arrayId}
              offset={-75}
              activeNavClass="is-active"
              scrollDuration="50"
              headerBackground="false"
            >
              <div className="nav-content">
                {hiddenPreviousButton ? (
                  <></>
                ) : (
                  <div className="box-buttons" style={{ marginLeft: "auto" }}>
                    <button onClick={() => handlePreviousButton()}>
                      <i className="fa fa-chevron-left"></i>
                    </button>
                  </div>
                )}
                <ul className="wrapper">
                  {categories?.map((item, index) => (
                    <li className="" key={index}>
                      <a href={`#${item.cod_secao}`}>{item.descricao}</a>
                    </li>
                  ))}
                </ul>

                {hiddenNextButton ? (
                  <></>
                ) : (
                  <div className="box-buttons" style={{ marginRight: "auto" }}>
                    <button onClick={() => handleNextButton()}>
                      <i className="fa fa-chevron-right"></i>
                    </button>
                  </div>
                )}
              </div>
            </ScrollspyNav>
          </nav>
          <div className="box-categories">
            {categories?.map((item, index) => (
              <>
                {item.descricao
                  .toLocaleLowerCase()
                  .includes(searchProduct.toLocaleLowerCase()) ? (
                  <section
                    id={`${item.cod_secao}`}
                    className="wrapper category"
                    key={index}
                  >
                    <div className="category-title">
                      <p>{item.descricao}</p>
                    </div>
                    {item.tb_produtos.map((item, index) => (
                      <div
                        key={index}
                        className="category-items"
                        onClick={() => {
                          setProduct(item);
                          setSelectedProduct(item)
                          console.log(selectedProduct)
                          history(`/menu/${empresa}/adicionar-pedido`, { state: { product: item } });


                        }}
                      >
                        {/* <Link to={`/menu/${empresa}/adicionar-pedido`} state={{ product }}> */}

                        <div className="category-text">
                          <p className="h5">{item.descricao}</p>
                          <p className="h6 text-black-50"> {item?.aplicacao}</p>
                          <p className="price">
                            a partir de: R$ {item.preco_venda_vista.toFixed(2)}
                          </p>
                        </div>
                        {/* </Link> */}
                        <div className="category-image">
                          {" "}
                          <img
                            loading="lazy"
                            crossOrigin="anonymous"
                            src={
                              process.env.REACT_APP_API_URL + `/${item.imagem}`
                            }
                            alt=""
                            onError={(e) => {
                              e.target.src = 'url-da-imagem-de-fallback';
                              e.target.style.objectFit = 'cover';
                            }}
                          // style={{ maxWidth: '100%', maxHeight: '100%' }}
                          />
                        </div>
                      </div>
                    ))}
                  </section>
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>
          <div className="establishment-footer">
            <span>Desenvolvido por </span>
            <a
              target="__blank"
              href="http://sistemacsistemas.com.br/"
              style={{ color: "blue" }}
            >
              Sistemac
            </a>
          </div>
        </div >
      )}

      <InformationModal
        open={openInformationModal}
        setModalCrudFalse={() => setOpenInformationModal(false)
        }
        orgData={orgData}
      />

      {
        localStorage.getItem("cart") && JSON.parse(localStorage.getItem("cart")).length > 0 &&
        <div className="fixed-bottom d-flex justify-content-center pb-4">
          <button className="btn btn-success btn-lg meu-btn-customizado"
            style={{ width: "90%", maxWidth: "500px" }}
            onClick={() => [history(`/menu/${empresa}/carrinho`)]}
          >
            <i className="fa-solid fa-cart-shopping"></i>
            <span style={{ marginLeft: "5px", marginRight: "1px" }}>
              Ver Carrinho
            </span>
            <span>
              {localStorage.getItem("cart") && JSON.parse(localStorage.getItem("cart")).length}
            </span>
          </button>
        </div>
      }
    </>
  );
};

export default Empresa;
