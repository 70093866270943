/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useContext } from "react";
import "./CloseOrder.scss";
import { toast } from "react-toastify";
import {
  createOrderMenu,
  deleteAddress,
  getAddress,
  getPaymentMethodByMenuWithParam,
  getServiceChargeWithParam,
  getEmpresaEndereco
} from "../../../../components/helpers/Api";
import IdentifyUserModal from "../../../../components/partials/modals/IdentifyUserModal";
import AddAdress from "../../../../components/partials/modals/AddAdress";
import { Context } from "../../../../components/authentication/AuthContext";
import ModalConfirm from "../../../../components/partials/modals/ModalConfirm";
import { Load } from "../../../../components/partials/spinner/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import PaymentMethodModal from "../../../../components/partials/modals/payment-method-modal/PaymentMethodModal";
import { getOrganizationMenu } from "../../../../components/helpers/Api";

import io from "socket.io-client";
import { use } from "react";

const socket = io(process.env.REACT_APP_API_URL);
socket.on("connect", (res) => {
});
const CloserOrder = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [loadingAddress, setLoadingAddres] = useState(false);
  const [addressData, setAddresData] = useState("");
  const [openIdentifyUserModal, setOpenIdentifyUserModal] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [editModal, setEditModal] = useState("");
  const [openModalAddAddress, setOpenModalAddAddress] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [idAddress, setIdAddres] = useState("");
  const [loadingModalConfirm, setLoadingModalConfirm] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodsWithValue, setPaymentMethodsWithValue] = useState([]);
  const [indexPayment, setIndexPayment] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    descricao: "selecione uma forma de pagamento",
  });
  const [needTroco, setNeedTroco] = useState(false);
  const [troco, setTroco] = useState(0);
  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [empresaEndereco, setEmpresaEndereco] = useState({});
  const {
    usuarioConsumidor,
    enderecoUsuarioConsumidor,
    setEnderecoUsuarioConsumidor,
  } = useContext(Context);
  const { empresa } = useParams();
  const history = useNavigate();

  function handleCloseOrder() {
    if (!deliveryMethod) {
      return toast.error("Selecione uma forma de entrega");
    }
    if (indexPayment === "") {
      return toast.error("Selecione uma forma de pagamento");
    }
    let totalPedido
    deliveryMethod === "delivery" ? totalPedido = subTotal + serviceCharge : totalPedido = subTotal

    if (troco) {
      if (troco < totalPedido) {
        return toast.error("valor do troco menor que o total!")
      }
    }
    const newPayment = [];
    paymentMethods.forEach((item) => {
      item.valor = 0;
      newPayment.push(item);
    });

    setPaymentMethodsWithValue(newPayment);

    let newValue = 0;

    if (localStorage.getItem("cart")) {
      JSON.parse(localStorage.getItem("cart")).forEach((item) => {
        newValue += item.amount * item.price;
      });
    };

    paymentMethodsWithValue[indexPayment].valor = newValue;

    let data = {
      id: usuarioConsumidor.id,
      telefone_cliente: usuarioConsumidor.telefone,
      nome_cliente: usuarioConsumidor.nome,
      company: empresa,
      valor_produtos: newValue,
      valor_servico: deliveryMethod === "delivery" ? serviceCharge : 0,
      meioPagamento: paymentMethodsWithValue,
      forma_pagemanto: selectedPaymentMethod,
      troco: troco,
      address: `${deliveryAddress.rua} ${deliveryAddress.numero ? `,${deliveryAddress.numero} ` : ""
        }${deliveryAddress.complemento ? `,${deliveryAddress.complemento} ` : ""
        }- ${deliveryAddress.bairro} - ${deliveryAddress.cidade} ${deliveryAddress.referencia ? `${deliveryAddress.referencia}` : ""
        }`,
      itens: localStorage.getItem("cart"),
    };
    setLoading(true);
    createOrderMenu(data)
      .then((res) => {
        if (res) {
          localStorage.removeItem("cart");
          socket.emit(`new_order`, {
            cod_org: localStorage.getItem("cod_org"),
            consumer: JSON.parse(localStorage.getItem("consumerUser")).nome,
            order: res.data.data,
          });
          history(`/menu/${empresa}/pedidos`);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.msg);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const org = localStorage.getItem("cod_org")

  function handleGetPaymentsMethods() {
    getPaymentMethodByMenuWithParam(org)
      .then((res) => {
        setPaymentMethods(res.data.data.rows);
        setEmpresaEndereco(res.data.endereco);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => { });
  };

  useEffect(() => {
    if (selectedPaymentMethod.descricao === "Dinheiro") {
      setNeedTroco(true);
    }
  }, [selectedPaymentMethod.descricao]);

  function handleGetAddresConsumerUser() {
    setLoadingAddres(true);
    if (usuarioConsumidor?.telefone) {
      getAddress(usuarioConsumidor?.telefone)
        .then((res) => {
          setEnderecoUsuarioConsumidor(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoadingAddres(false);
        });
    }
  }
  function handleDeleteAddress() {
    setLoadingModalConfirm(true);
    deleteAddress(idAddress)
      .then((res) => {
        setLoadingAddres(true);
        setTimeout(() => {
          handleGetAddresConsumerUser();
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setOpenModalConfirm(false);
        setLoadingModalConfirm(false);
      });
  };

  function handleGetServiceCharge() {
    getServiceChargeWithParam(org)
      .then((res) => {
        setServiceCharge(parseFloat(res.data.data.taxa_entrega));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => { });
  };

  useEffect(() => {
    setOpenIdentifyUserModal(true);
    handleGetAddresConsumerUser();
    handleGetPaymentsMethods();
    handleGetServiceCharge();

    let newValue = 0;
    if (localStorage.getItem("cart")) {
      JSON.parse(localStorage.getItem("cart")).forEach((item) => {
        newValue += item.amount * item.price;
      });
    }

    setSubTotal(newValue);
  }, []);

  useEffect(() => {
    const newPayment = [];
    paymentMethods.forEach((item) => {
      item.valor = 0;

      newPayment.push(item);
    });
    setPaymentMethodsWithValue(newPayment);
  }, [paymentMethods]);

  return (
    <>
      <div id="box-mui-info-user" className="">
        <div className="identify-modal">
          <p className="identify-title">Finalizar Pedido</p>
          <label className="mt-3 destiny">Este pedido será entregue à:</label>
          <div className="d-flex justify-content-between m-2">
            <div>
              <p className="fw-bolder">{usuarioConsumidor?.nome}</p>
              <p className="fw-bolder">{usuarioConsumidor?.telefone}</p>
            </div>
            {usuarioConsumidor?.nome || usuarioConsumidor?.telefone ? (
              <button
                className="back my-auto"
                style={{
                  width: "100px",
                  height: "35px",
                  borderRadius: "5px",
                  marginLeft: "10px",
                }}
                onClick={() => [
                  setOpenIdentifyUserModal(true),
                  setEditModal("edit"),
                ]}
              >
                Editar
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="delivery-method">
            <p className="method-title">Selecione a forma de Entrega</p>
            <div className="delivery-content" style={{ borderTop: "none" }}>
              <p className="sub-title">
                Endereço de Entrega{" "}
                {enderecoUsuarioConsumidor?.length > 0 ? (
                  <button
                    className="back my-auto"
                    style={{
                      height: "25px",
                      borderRadius: "5px",
                      marginLeft: "10px",
                      fontSize: "11px",
                    }}
                    onClick={() => [
                      setIsEdit(false),
                      setOpenModalAddAddress(true),
                    ]}
                  >
                    Adicionar Endereço
                  </button>
                ) : (
                  <></>
                )}
              </p>

              {enderecoUsuarioConsumidor?.length > 0 ? (
                <div style={{ maxHeight: 140, overflowY: "auto" }}>
                  {loadingAddress ? (
                    <Load />
                  ) : (
                    <>
                      {
                        enderecoUsuarioConsumidor?.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              borderTop: "2px solid #f2f2f2",
                              marginTop: 10,
                            }}
                          >
                            <div className="delivery-sub-content">
                              <div className="first-box">
                                <span>
                                  {item.rua},{item.numero},{item.complemento}
                                </span>
                                <br />
                                <span>{item.bairro}</span>
                                <br />
                                <span>{item.referencia}</span>
                              </div>
                              <div
                                className="second-box btn-group"
                                style={{ paddingRight: "20px" }}
                              >
                                <label className="btn-default yellow">
                                  <input
                                    name="delivery-method"
                                    type="radio"
                                    value="delivery"
                                    onChange={(e) => {
                                      setDeliveryMethod(e.target.value);
                                      setDeliveryAddress({ ...item }); // Mantém o endereço escolhido para entrega
                                    }}
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="edit-dete">
                              <button
                                onMouseMove={() => setIdAddres(item.cod_endereco)}
                                onClick={() => setOpenModalConfirm(true)}
                                className="delete-button"
                              >
                                Excluir
                              </button>
                              <button
                                style={{ marginLeft: "10px" }}
                                className="edit-button"
                                onClick={() => [
                                  setIsEdit(true),
                                  setAddresData(item),
                                  setOpenModalAddAddress(true),
                                ]}
                              >
                                Editar
                              </button>
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              ) : (
                <button
                  className="save"
                  onClick={() => [
                    setIsEdit(false),
                    setOpenModalAddAddress(true),
                  ]}
                >
                  <i className="fa fa-plus"></i>
                  Adicionar Endereço
                </button>
              )}
            </div>
            <div className="delivery-content">
              <p className="sub-title">Retirar no Estabelecimento</p>
              <div className="delivery-sub-content">
                <div className="first-box">
                  <span>{empresaEndereco?.logradouro},{empresaEndereco?.numero} </span> <br />
                  <span>{empresaEndereco?.bairro}, {empresaEndereco?.cidade} - {empresaEndereco?.uf}</span>
                </div>
                <div className="second-box btn-group">
                  <label className="btn-default yellow">
                    <input
                      name="delivery-method"
                      type="radio"
                      value="to-remove"
                      onChange={(e) => {
                        setDeliveryMethod(e.target.value);
                        setDeliveryAddress(empresaEndereco); // Remove o endereço salvo
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="delivery-content">
              <p className="sub-title">Consumir no local</p>
              <div className="delivery-sub-content">
                <div className="first-box">
                  <span>{empresaEndereco?.logradouro},{empresaEndereco?.numero} </span> <br />
                  <span>{empresaEndereco?.bairro}, {empresaEndereco?.cidade} - {empresaEndereco?.uf}</span>
                </div>
                <div className="second-box">
                  <label htmlFor="">
                    <input
                      name="delivery-method"
                      type="radio"
                      value="consume"
                      onChange={(e) => {
                        setDeliveryMethod(e.target.value);
                        setDeliveryAddress(empresaEndereco); // Remove o endereço salvo
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-methods">
            <label
              className="form-control"
              htmlFor=""
              style={{ width: "100%", marginTop: "20px" }}
            >
              <span className="sub-title">Forma de Pagamento: </span>
              <button
                className="form-select payment-method-description"
                style={{ height: 40, textAlign: "start" }}
                onClick={() => setOpenPaymentMethodModal(true)}
              >
                {selectedPaymentMethod?.descricao}
              </button>
            </label>
          </div>
          {needTroco ?
            <div className="payment-methods">
              <label
                className="form-control"
                htmlFor=""
                style={{ width: "100%", marginTop: "20px" }}
              >
                <span className="sub-title">Troco: </span>
                <input
                  type="number"
                  name="person"
                  value={null}

                  //  className={`${error.includes("persons-error") ? "error" : ""}`}
                  onChange={(e) => [setTroco(e.target.value)]}
                />
              </label>
            </div> : ""}
          <div>
            <label
              className="form-control"
              htmlFor=""
              style={{ width: "100%", marginTop: "20px" }}
            >
              <span className="sub-title">Valores a se pagar:</span>
              <div className="value-information p-2">
                <p>Produtos: R$ {subTotal.toFixed(2)}</p>
                <p>
                  Serviço: R${" "}
                  {deliveryMethod === "delivery"
                    ? serviceCharge
                    : "0.00"}
                </p>
              </div>
              <p className="value-total p-2">
                <span>Total:</span>{" "}
                <span>
                  R${" "}
                  {deliveryMethod === "delivery"
                    ? (subTotal + serviceCharge)
                    : subTotal.toFixed(2)}
                </span>
              </p>
            </label>
          </div>

          <div className="box-button">
            <button
              className="back"
              onClick={() => history(`/menu/${empresa}/carrinho`)}
            >
              Voltar
            </button>
            <button
              className="save"
              onClick={() => (!loading ? handleCloseOrder() : "")}
              disabled={loading}
            >
              {!loading && <span className="indicator-label">Enviar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      <IdentifyUserModal
        open={openIdentifyUserModal}
        edit={editModal}
        editModalFalse={() => setEditModal("")}
        setModalIdentifyFalse={() => setOpenIdentifyUserModal(false)}
      />
      <AddAdress
        open={openModalAddAddress}
        getAddress={() => handleGetAddresConsumerUser()}
        editData={addressData}
        isEdit={isEdit}
        setModalAddAddresFalse={() => setOpenModalAddAddress(false)}
        cidade={empresaEndereco?.cidade}
      />
      <ModalConfirm
        title="Tem certeza que deseja deletar este endereço?"
        open={openModalConfirm}
        setModalCrudFalse={() => setOpenModalConfirm(false)}
        handleRequest={() => handleDeleteAddress()}
        loading={loadingModalConfirm}
        textBack="Cancelar"
        textSubmit="Deletar"
      />
      <PaymentMethodModal
        open={openPaymentMethodModal}
        data={paymentMethods}
        setDataPaymentMthod={(data) => [
          setSelectedPaymentMethod(data),
          setOpenPaymentMethodModal(false),
        ]}
        setFalse={() => setOpenPaymentMethodModal(false)}
        setIndex={(index) => setIndexPayment(index)}
      />
    </>
  );
};

export default CloserOrder;
