import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import "./ScheduleModal.scss";

const daysOfWeek = [
    { name: "Domingo", key: "sunday" },
    { name: "Segunda-feira", key: "monday" },
    { name: "Terça-feira", key: "tuesday" },
    { name: "Quarta-feira", key: "wednesday" },
    { name: "Quinta-feira", key: "thursday" },
    { name: "Sexta-feira", key: "friday" },
    { name: "Sábado", key: "saturday" },
];

// Define o schedule padrão usando os valores do daysOfWeek
const defaultSchedule = daysOfWeek.reduce((acc, day) => {
    acc[day.key] = { open: "09:00", close: "14:00", disabled: false };
    return acc;
}, {});

const ScheduleModal = ({ open, setOpen, funcionamento, setFuncionamento }) => {
    console.log(funcionamento)
    // Se funcionamento existir e não estiver vazio, utiliza-o; caso contrário, usa defaultSchedule
    const [schedule, setSchedule] = useState(
        funcionamento && Object.keys(funcionamento).length > 0
            ? funcionamento
            : defaultSchedule
    );

    // Atualiza o estado sempre que funcionamento mudar
    useEffect(() => {
        if (funcionamento && Object.keys(funcionamento).length > 0) {
            setSchedule(funcionamento);
        } else {
            setSchedule(defaultSchedule);
        }
    }, [funcionamento]);

    const handleChange = (dayKey, field, value) => {
        setSchedule((prev) => ({
            ...prev,
            [dayKey]: { ...prev[dayKey], [field]: value },
        }));
    };

    const handleDisable = (dayKey) => {
        setSchedule((prev) => ({
            ...prev,
            [dayKey]: { ...prev[dayKey], disabled: !prev[dayKey].disabled },
        }));
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box className="schedule-modal">
                <div className="modal-header">
                    <h2>Definir Horários</h2>
                    <button className="close-button" onClick={() => setOpen(false)}>
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    {daysOfWeek.map(({ name, key }) => (
                        <div key={key} className="day-row">
                            <input
                                type="checkbox"
                                checked={schedule[key].disabled}
                                onChange={() => handleDisable(key)}
                                className="day-checkbox"
                            />
                            <span className="day-name">{name}</span>
                            <input
                                type="time"
                                value={schedule[key].open}
                                onChange={(e) => handleChange(key, "open", e.target.value)}
                                disabled={schedule[key].disabled}
                                className="time-input"
                            />
                            <span className="separator">às</span>
                            <input
                                type="time"
                                value={schedule[key].close}
                                onChange={(e) => handleChange(key, "close", e.target.value)}
                                disabled={schedule[key].disabled}
                                className="time-input"
                            />
                        </div>
                    ))}
                </div>
                <div className="modal-footer">
                    <button onClick={() => setOpen(false)} className="cancel-button">
                        Cancelar
                    </button>
                    <button
                        onClick={() => {
                            setFuncionamento(schedule);
                            setOpen(false);
                        }}
                        className="save-button"
                    >
                        Salvar
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default ScheduleModal;
