import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import "./Modals.scss";
import { toast } from "react-toastify";
import { getProductTamanho, getProductSabores, getProductAdd, getOrganizationMenu } from "../../../components/helpers/Api";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const SelectProductModal = ({ ...props }) => {
  function handleModal(e) {
    if (e.nativeEvent.target.id === "box-mui-select") {
      props.setModalSelectFalse();
    }
  }
  const [status, setStatus] = useState(false)
  const [amountProduct, setAmountProduct] = useState(1);
  const [observation, setObservation] = useState("");
  const [additionals, setAdditionals] = useState([]);
  const [hasIndexies, setHasIndexies] = useState([]);
  const [limitSection, setLimitSection] = useState([]);
  const [tamanhos, setTamanhos] = useState([]);
  const [selectedTamanho, setSelectedTamanho] = useState(null);
  const [sabores, setSabores] = useState([]);
  const [selectedSabores, setSelectedSabores] = useState([]);
  const [saborString, setSaborString] = useState("");
  const [sumAdd, setSumAdd] = useState(0)
  const [alladditionals, setALLAdditionals] = useState([]);
  const [data, setData] = useState([]);
  let [price, setPrice] = useState(0);
  const [product, setProduct] = useState(0);

  const [originalPriceProduct, setOriginalPriceProduct] = useState(0);
  const { empresa } = useParams();

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (location.state?.product) {
      setProduct(location.state.product);
    }
  }, [location, navigate])

  function handleCart() {
    let localStorageCart = JSON.parse(localStorage.getItem("cart")) || [];
    const cartItem = {
      observation,
      amount: amountProduct,
      name: selectedTamanho
        ? `${product.descricao} ${(saborString)} - ${selectedTamanho.descricao}`
        : product.descricao,
      price,
      image: product.imagem,
      cod_pk_produto: product.cod_produto,
      additionals,
      additionalsPrice: price - originalPriceProduct,
    };

    localStorageCart.push(cartItem);
    localStorage.setItem("cart", JSON.stringify(localStorageCart));
    // props.setModalSelectFalse();
  }
  useEffect(() => {
    console.log("Updated Price:", sumAdd);
  }, [sumAdd]);

  function handleAddAdditionals(indexSection, indexProduct, product, isPlus) {
    const productPrice = product.preco_venda_vista;
    // Atualiza preço e soma
    setSumAdd((prevSum) => prevSum + productPrice);
    setPrice((prevPrice) => prevPrice + productPrice);

    // Atualiza o estado "data" (que guarda as seções e produtos)
    const updatedData = [...data];
    if (!updatedData[indexSection].tb_produtos[indexProduct].quantidade) {
      updatedData[indexSection].tb_produtos[indexProduct].quantidade = 0;
    }
    updatedData[indexSection].tb_produtos[indexProduct].quantidade += 1;
    updatedData[indexSection].quantidade += 1;

    if (updatedData[indexSection].quantidade === updatedData[indexSection].web_quantidade) {
      setLimitSection([...limitSection, indexSection]);
    }

    setData(updatedData);
    setAdditionals((prev) => [
      ...prev.filter((item) => item.cod_produto !== product.cod_produto),
      {
        amount: updatedData[indexSection].tb_produtos[indexProduct].quantidade,
        name: `${product.descricao} -`,
        price: productPrice,
        cod_produto: product.cod_produto,
        cod_secao: product.cod_fk_secao,
      },
    ]);

    // Atualiza o estado "alladditionals" para refletir a nova quantidade
    setALLAdditionals((prev) =>
      prev.map((item) => {
        if (item.cod_produto === product.cod_produto) {
          return {
            ...item,
            quantidade: updatedData[indexSection].tb_produtos[indexProduct].quantidade,
          };
        }
        return item;
      })
    );
  }


  function handleSubAdditionals(indexSection, indexProduct, product) {
    const updatedData = [...data];
    const productPrice = product.preco_venda_vista;

    // Verifica se a quantidade do produto é maior que 0 antes de decrementar
    if (updatedData[indexSection].tb_produtos[indexProduct].quantidade > 0) {
      updatedData[indexSection].tb_produtos[indexProduct].quantidade -= 1;
      updatedData[indexSection].quantidade -= 1;

      setSumAdd((prevSum) => prevSum - productPrice);
      setPrice((prevPrice) => prevPrice - productPrice);

      if (updatedData[indexSection].quantidade < updatedData[indexSection].web_quantidade) {
        setLimitSection((prev) => prev.filter((i) => i !== indexSection));
      }

      setAdditionals((prev) => {
        const updatedAdditionals = [...prev];
        const additionalIndex = updatedAdditionals.findIndex(
          (item) => item.cod_produto === product.cod_produto
        );

        if (additionalIndex !== -1) {
          if (updatedData[indexSection].tb_produtos[indexProduct].quantidade > 0) {
            updatedAdditionals[additionalIndex].amount =
              updatedData[indexSection].tb_produtos[indexProduct].quantidade;
          } else {
            updatedAdditionals.splice(additionalIndex, 1);
          }
        }
        return updatedAdditionals;
      });

      // Atualiza também o estado "alladditionals"
      setALLAdditionals((prev) =>
        prev.map((item) => {
          if (item.cod_produto === product.cod_produto) {
            return {
              ...item,
              quantidade: updatedData[indexSection].tb_produtos[indexProduct].quantidade,
            };
          }
          return item;
        })
      );

      setData(updatedData);
    }
  }


  function handleTamanhoChange(event) {
    const tamanhoSelecionado = tamanhos.find(
      (tamanho) => tamanho.descricao === event.target.value
    );
    if (tamanhoSelecionado) {
      setSelectedTamanho(tamanhoSelecionado);
      setPrice(tamanhoSelecionado.valor + sumAdd);
      if (selectedSabores.length > 0) {
        fetchSaborPrices(selectedSabores, tamanhoSelecionado);
      }
    }
  }

  function handleSaborChange(event) {
    const { value, checked } = event.target;

    setSelectedSabores((prev) => {
      const updated = checked
        ? [...prev, value]
        : prev.filter((sabor) => sabor !== value);

      fetchSaborPrices(updated, selectedTamanho);
      return updated;
    });
  }

  function fetchSaborPrices(selectedSabores, selectedTamanho) {
    const saborPricesPromises = selectedSabores.map((sabor) => {
      const selectedSabor = sabores.find((secao) =>
        secao.tb_produtos.some((produto) => produto.descricao === sabor)
      );

      if (selectedSabor) {
        return Promise.all(
          selectedSabor.tb_produtos.map((produto) =>
            getProductTamanho(produto.cod_produto)
          )
        )
          .then((res) => {
            const valoresFiltrados = res.flatMap((response) =>
              response.data
                .filter(
                  (tamanho) =>
                    tamanho.descricao === selectedTamanho?.descricao
                )
                .map((tamanho) => parseFloat(tamanho.valor) || 0)
            );

            return valoresFiltrados.length > 0
              ? Math.max(...valoresFiltrados)
              : 0;
          })
          .catch((error) => {
            console.error("Erro ao buscar tamanhos para o sabor: ", error);
            return 0;
          });
      }

      return Promise.resolve(0);
    });

    Promise.all(saborPricesPromises).then((prices) => {
      const maxSaborPrice = Math.max(0, ...prices);
      const basePrice = selectedTamanho?.valor || 0;
      const updatedPrice = basePrice + maxSaborPrice;
      setPrice(updatedPrice);
    });
  }

  useEffect(() => {
    if (product) {
      const { tb_secaos, cod_produto, preco_venda_vista } = product;

      const initializedSecaos = tb_secaos.map((secao) => ({
        ...secao,
        quantidade: 0,
        tb_produtos: secao.tb_produtos.map((prod) => ({ ...prod, quantidade: 0 })),
      }));

      setData(initializedSecaos);
      setPrice(preco_venda_vista);
      setOriginalPriceProduct(preco_venda_vista);

      handleGetAdicionais(cod_produto);
      handleGetTamanhos(cod_produto);
      handleGetSabores(cod_produto);
    }
  }, [product]);

  function handleGetAdicionais(cod_produto) {
    if (cod_produto) {
      const cod_org = localStorage.getItem("cod_org")
      console.log(cod_org)
      getProductAdd(cod_produto, cod_org)
        .then((res) => {
          // Map the additionals to include quantidade property
          const formattedAdditionals = res.data.additionals.map(item => ({
            ...item,
            quantidade: 0
          }));
          setALLAdditionals(formattedAdditionals);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setALLAdditionals([]);
    }
  }

  function handleGetTamanhos(id) {
    if (id) {
      getProductTamanho(id)
        .then((res) => {
          setTamanhos(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setTamanhos([]);
    }
  }

  function handleGetSabores(id) {
    if (id) {
      getProductSabores(id)
        .then((res) => {
          setSabores(res.data.product.tb_secaos);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setSabores([]);
    }
  }

  useEffect(() => {
    const saboresString = selectedSabores.join(", ");
    setSaborString(saboresString);
  }, [selectedSabores]);

  function handleClearInput() {
    document.querySelectorAll("[type=radio]").forEach((item) => {
      item.checked = false;
    });
  }

  const handleAddToCart = () => {
    setAmountProduct(1);
    handleCart();
    setTamanhos([]);
    setSabores([]);
    setAdditionals([]);
    setHasIndexies([]);
    handleClearInput();
    setALLAdditionals([]);
    setObservation("");
  };

  function handleGetDataMenu() {
    getOrganizationMenu(empresa)
      .then((res) => {
        console.log(res)
        localStorage.setItem("minimum_price", res.data.data.preco_minimo);
        localStorage.setItem("cod_org", res.data.data.cod_org);

        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();

        const funcionamento = res.data.data?.funcionamento

        const today = new Date().toLocaleDateString("en-US", { weekday: "long" }).toLowerCase();
        const dayManager = funcionamento.dias[today] || null;
        const [openHour, openMinute] = dayManager.open.split(":").map(Number);
        const [closeHour, closeMinute] = dayManager.close.split(":").map(Number);

        const openMinuteStr = String(openMinute).padStart(2, '0');
        const closeMinuteStr = String(closeMinute).padStart(2, '0');

        const toMinutes = (hours, minutes) => hours * 60 + minutes;
        const currentTotalMinutes = toMinutes(currentHours, currentMinutes);
        const openingTotalMinutes = toMinutes(openHour, openMinute);
        const closingTotalMinutes = toMinutes(closeHour, closeMinute);

        if (!dayManager.disabled) {
          console.log(currentTotalMinutes, closingTotalMinutes)

          if (currentTotalMinutes >= openingTotalMinutes && currentTotalMinutes <= closingTotalMinutes) {
            setStatus(true);
          }
        } else {
          setStatus(false)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    handleGetDataMenu()
  }, [])

  const getImageSrc = () => {
    return product?.imagem && product.imagem !== "uploads/products/blank-image.png"
      ? `${process.env.REACT_APP_API_URL}/${product.imagem}`
      : "uploads/products/blank-image.png";
  };

  console.log(additionals)

  return (
    <>
      <div id="box-mui-select" onClick={(e) => handleModal(e)}>
        <div className="select-modal">
          <div className="box-image">
            <img
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "350px",
                // maxHeight: "500px",
                marginBottom: "6px",
              }}
              crossOrigin="anonymous"
              src={getImageSrc()}
              alt=""
            />
            <button
              className="back-products"
              onClick={() => {
                navigate(`/menu/${empresa}`);
                setObservation("");
              }}
            >
              <span>
                <i style={{ color: "black" }} className="fa-solid fa-left-long fa-sm"></i>
              </span>
            </button>
            <h4 style={{ paddingLeft: "10px" }}> {product.descricao}</h4>
          </div>
          <div className="select-content">

            {/* Seção de tamanhos */}
            {tamanhos.length > 0 && (
              <section style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#555",
                    marginBottom: "10px",
                  }}
                >
                  Selecione um tamanho
                </p>
                <div

                  className="tamanhos"
                >
                  {tamanhos.map((tamanho, index) => {
                    const id = `tamanho-${tamanho.cod_tamanho}-${index}`; // Garante um id único
                    return (
                      <label
                        key={id}
                        htmlFor={id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "10px",
                          border: "1px solid #e0e0e0",
                          borderRadius: "5px",
                          backgroundColor: "#f9f9f9",
                          transition: "background-color 0.3s ease",
                          paddingBottom: "6px",
                          cursor: "pointer" // indica que é clicável
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f0f0f0")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f9f9f9")
                        }
                      >
                        <input
                          type="radio"
                          name="tamanho"
                          id={id}
                          onChange={handleTamanhoChange}
                          value={tamanho.descricao}
                          style={{ marginRight: "10px" }}
                        />
                        <span>{tamanho.descricao}</span>
                        <span
                          style={{
                            fontWeight: "600",
                            color: "#333",
                            fontSize: "14px",
                          }}
                        >
                          R${parseFloat(tamanho.valor).toFixed(2)}
                        </span>
                      </label>
                    );
                  })}
                </div>
              </section>
            )}


            {/* Seção de sabores */}
            {Array.isArray(sabores) &&
              sabores.length > 0 &&
              sabores.map((secao) => (
                <section key={secao.cod_secao} style={{ marginTop: "20px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#555",
                      marginBottom: "10px",
                    }}
                  >
                    Selecione um Sabor
                  </p>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                      gap: "15px",
                      marginTop: "10px",
                    }}
                  >
                    {secao.tb_produtos.map((produto, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "10px",
                          border: "1px solid #e0e0e0",
                          borderRadius: "5px",
                          backgroundColor: "#f9f9f9",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f0f0f0")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f9f9f9")
                        }
                      >
                        <label>
                          <input
                            type="checkbox"
                            value={produto.descricao}
                            onChange={handleSaborChange}
                          />
                          {produto.descricao}
                        </label>
                      </div>
                    ))}
                  </div>
                </section>
              ))}

            {/* Seção de adicionais */}
            {data?.map((item, index) => (
              <React.Fragment key={index}>
                {item.acompanhamento === "S" && (
                  <div className="additionals">
                    <p
                      className="sub-title"
                      style={{
                        textAlign: "center",
                        borderBottom: "2px solid #f2f2f2",
                      }}
                    >
                      Adicionais
                    </p>
                    <section className="additional">
                      <p className="sub-title--additional">
                        {item?.descricao} (Escolha até {item?.web_quantidade} itens)
                      </p>
                      {alladditionals?.map((subItem, subIndex) => (
                        subItem.cod_fk_secao === item.cod_secao && (
                          <div className="additional-item" key={subIndex}>
                            <div className="item-information">
                              <span className="item-information-title">
                                {subItem.descricao}
                              </span>
                              <br />
                              <span className="item-information-price">
                                R${parseFloat(subItem.preco_venda_vista).toFixed(2)}
                              </span>
                            </div>
                            <div className="item-action">
                              {item.web_quantidade > 1 ? (
                                <div className="amount">
                                  {/* Botão de diminuir quantidade */}
                                  <i
                                    onClick={() => {
                                      if (subItem.quantidade > 0) {

                                        handleSubAdditionals(index, subIndex, subItem); // Chama a função para diminuir a quantidade
                                      }
                                    }}
                                    className="fa fa-minus"
                                    style={{
                                      color: subItem.quantidade > 0 ? "black" : "gray",
                                      cursor: subItem.quantidade > 0 ? "pointer" : "not-allowed",
                                      opacity: subItem.quantidade > 0 ? 1 : 0.5,
                                    }}
                                  ></i>

                                  {/* Quantidade do item */}
                                  <span id={`product-${subItem.cod_produto}`}>
                                    {subItem.quantidade || 0}
                                  </span>

                                  {/* Botão de aumentar quantidade */}
                                  <i
                                    onClick={() => {
                                      const totalAmount = additionals.reduce((acc, item) => acc + item.amount, 0);

                                      if (
                                        hasIndexies.includes(subIndex) ||
                                        limitSection.includes(index) ||
                                        totalAmount >= item?.web_quantidade ||
                                        additionals.length >= item?.web_quantidade
                                      ) {
                                        toast.error("limite de adicionais alcançado");
                                        return;
                                      }
                                      handleAddAdditionals(index, subIndex, subItem, true);
                                    }}
                                    className="fa fa-plus"
                                    style={{
                                      color:
                                        hasIndexies.includes(subIndex) || limitSection.includes(index)
                                          ? "gray"
                                          : "black",
                                      opacity:
                                        hasIndexies.includes(subIndex) || limitSection.includes(index)
                                          ? 0.5
                                          : 1,
                                      cursor:
                                        hasIndexies.includes(subIndex) || limitSection.includes(index)
                                          ? "not-allowed"
                                          : "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : (
                                <input
                                  type="radio"
                                  name={`additional--one-${item.cod_secao}`}
                                  onChange={() =>
                                    handleAddAdditionals(index, subIndex, subItem, false)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        )
                      ))}
                    </section>
                  </div>
                )}
              </React.Fragment>
            ))}

            {/* Seção de observação */}
            <div className="observation">
              <p className="sub-title">Alguma Observação?</p>
              <div className="d-flex justify-content-end ">
                <span>{observation.length}/150</span>
              </div>
              <textarea
                name="observation"
                rows="5"
                placeholder="Adicione as Observações aqui"
                onChange={(e) => setObservation(e.target.value)}
                value={observation}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                maxLength="150"
              />
            </div>

            {/* Seção de adicionar produto */}
            <div className="add-product"
              style={{
                // marginTop: "20px",
                position: "fixed", // ou "absolute" se preferir relativo ao modal
                bottom: "0px", // ajuste conforme necessário
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1000,
                width: "100%",
                background: "#fff",
                padding: "5px"
              }}
            >
              <div style={{ whiteSpace: "nowrap" }} className="amount">
                {/* Botão de diminuir quantidade */}
                <i
                  onClick={() => {
                    if (amountProduct > 1) {
                      setAmountProduct(prev => prev - 1);
                    }
                  }}
                  className="fa fa-minus"
                  style={{
                    color: amountProduct > 1 ? "black" : "gray",
                    cursor: amountProduct > 1 ? "pointer" : "not-allowed",
                    opacity: amountProduct > 1 ? 1 : 0.5,
                    transition: "opacity 0.3s ease",
                  }}
                ></i>

                {/* Quantidade do produto */}
                <span>{amountProduct}</span>

                {/* Botão de aumentar quantidade */}
                <i
                  onClick={() => setAmountProduct(prev => prev + 1)}
                  className="fa fa-plus"
                  style={{
                    color: "black",
                    cursor: "pointer",
                    transition: "opacity 0.3s ease",
                  }}
                ></i>
              </div>

              {/* Botão de adicionar ao carrinho */}
              <button

                className="add-button"
                onClick={() => {
                  if (status) {
                    if (tamanhos.length > 0 && selectedTamanho === null) {
                      toast.warning("Escolha um tamanho!!");
                    } else {
                      handleAddToCart();
                      navigate(`/menu/${empresa}`);

                    }
                  } else {
                    toast.warning("Loja fechada no momento!!");
                  }
                }}
              >
                <span style={{ marginRight: "25px" }}>
                  <i className="fa fa-plus"></i>
                </span>
                Adicionar R$ {(amountProduct * price).toFixed(2)}
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default SelectProductModal;