import React, { useEffect, useState, useContext } from "react";
import { Box, Modal } from "@mui/material";
import "./Modals.scss";
import { toast } from "react-toastify";
import { createAddress, editAddress } from "../../helpers/Api";
import { Context } from "../../authentication/AuthContext";

const AddAdress = ({ ...props }) => {
  const [neighborhood, setNeighborhood] = useState("");
  const [street, setStreet] = useState("");
  const [complement, setComplement] = useState("");
  const [numberHouse, setNumberHouse] = useState("");
  const [noNumber, setNoNumber] = useState(false);
  const [reference, setReference] = useState("");
  const [loading, setLoading] = useState(false);
  const [idAddress, setIdAddress] = useState("");
  const [city, setCity] = useState("");
  const { usuarioConsumidor } = useContext(Context);

  useEffect(() => {
    setCity(props?.editData.cidade);
    setNeighborhood(props.editData?.bairro);
    setStreet(props.editData?.rua);
    setNumberHouse(props.editData?.numero);
    setComplement(props.editData?.complemento);
    setReference(props.editData?.referencia);
    setIdAddress(props.editData?.cod_endereco);
  }, [props.editData]);

  useEffect(() => {
    setCity(props.cidade)
  }, [props.cidade]);


  function handleEditAddress() {
    if (neighborhood?.length < 1) {
      toast.error("Insira o seu bairro");
      return;
    } else if (street?.length < 1) {
      toast.error("Insira sua rua");
      return;
    } else if (numberHouse?.length < 1 && !noNumber) {
      toast.error("Insira o número da sua casa");
      return;
    } else if (city?.length < 1 && !noNumber) {
      toast.error("Insira sua cidade");
      return;
    } else if (numberHouse?.length > 10) {
      return;
    }

    setLoading(true);
    let dataUser = {
      telefone: usuarioConsumidor?.telefone,
      id: usuarioConsumidor?.id,
      bairro: neighborhood,
      rua: street,
      numero: numberHouse,
      complemento: complement,
      referencia: reference,
      cidade: city,
    };

    editAddress(idAddress, dataUser)
      .then((res) => {
        props.setModalAddAddresFalse();
        props.getAddress();
        setNeighborhood("");
        setStreet("");
        setNumberHouse("");
        setComplement("");
        setReference("");
        setCity("");
        setNoNumber(false);
        toast.success("Endereço editado com sucesso!!");
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Erro ao cadastrar endereço, verifique os dados e tente novamente!!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handleCreateAddress() {
    if (neighborhood?.length < 1) {
      toast.error("Insira o seu bairro");
      return;
    } else if (street?.length < 1) {
      toast.error("Insira sua rua");
      return;
    } else if (numberHouse?.length < 1 && !noNumber) {
      toast.error("Insira o número da sua casa");
      return;
    } else if (city?.length < 1 && !noNumber) {
      toast.error("Insira sua cidade");
      return;
    } else if (numberHouse?.length > 10) {
      return;
    }

    setLoading(true);
    let dataUser = {
      cod_org: localStorage.getItem("cod_org"),
      telefone: usuarioConsumidor?.telefone,
      id: usuarioConsumidor?.id,
      bairro: neighborhood,
      rua: street,
      numero: numberHouse,
      complemento: complement,
      referencia: reference,
      cidade: city,
    };

    createAddress(dataUser)
      .then(() => {
        props.setModalAddAddresFalse();
        props.getAddress();
        setNeighborhood("");
        setStreet("");
        setNumberHouse("");
        setComplement("");
        setReference("");
        setCity("");
        setNoNumber(false);
        toast.success("Endereço criado com sucesso!!");
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Erro ao cadastrar endereço, verifique os dados e tente novamente!!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => []}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted
      >
        <Box id="box-mui-add-address" className="">
          <div className="address-modal">
            <label className="mt-3">Endereço para a entrega</label>
            <div className="form-control address-content">
              <label className="mt-2">Rua</label>
              <input
                className=""
                type="text"
                onChange={(e) => setStreet(e.target.value)}
                value={street}
              />
              <label className="mt-2">Cidade</label>
              <input
                className=""
                type="text"
                onChange={(e) => setCity(e.target.value)}
                value={city}
              />
              <label className="mt-2">Número</label>
              <input
                className=""
                type="text"
                onChange={(e) => setNumberHouse(e.target.value)}
                value={numberHouse}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                disabled={noNumber}
                maxLength={10}
              />
              <label className="mt-2">Bairro</label>
              <input
                className=""
                type="text"
                onChange={(e) => setNeighborhood(e.target.value)}
                value={neighborhood}
              />
              <div className="d-flex mt-2 mb-2">
                <div
                  className="check-box-number"
                  onClick={() => [setNumberHouse(""), setNoNumber(!noNumber)]}
                  role="button"
                >
                  {noNumber ? (
                    <i
                      class="fa-solid fa-check text-white fs-6"
                      style={{ margin: "0 3px" }}
                    ></i>
                  ) : (
                    <></>
                  )}
                </div>
                <p style={{ margin: "auto 5px", fontWeight: "600" }}>
                  Sem Número
                </p>
              </div>
              <label className="mt-2">Complemento</label>
              <input
                className=""
                type="text"
                onChange={(e) => setComplement(e.target.value)}
                value={complement}
                placeholder="Ex: Lt 12"
              />

              <div className="mt-3">
                <div className="d-flex justify-content-between ">
                  <label>Referência</label>

                  <span>{reference?.length}/150</span>
                </div>
                <textarea
                  onChange={(e) => setReference(e.target.value)}
                  value={reference}
                  maxLength={150}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div className="box-button">
              <button
                className="back"
                onClick={() => props.setModalAddAddresFalse()}
              >
                Voltar
              </button>
              <button
                disabled={loading}
                className="save"
                onClick={() =>
                  props.isEdit ? handleEditAddress() : handleCreateAddress()
                }
              >
                {!loading && <span className="indicator-label">Salvar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddAdress;
