import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import axios from "axios";
import "./WhatsAppModal.scss"; // Importação do CSS externo

const WhatsAppModal = ({ open, setOpen }) => {
    const [phone, setPhone] = useState("");
    const [qrCode, setQrCode] = useState("");
    const [status, setStatus] = useState("");
    const cod_org = localStorage.getItem("cod_org")
    const startSession = async () => {
        try {
            const headers = { "authorization": localStorage.getItem("token") }
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/telefone/start-session`, { phone, cod_org }, { headers });

            setQrCode(response.data.qrCode);
            console.log(qrCode)
        } catch (error) {
            console.error("Erro ao iniciar sessão:", error);
        }
    };

    useEffect(() => {
        if (qrCode) {
            console.log("QR Code atualizado:", qrCode);
        }
    }, [qrCode]);

    const checkStatus = async () => {
        try {
            const headers = { "authorization": localStorage.getItem("token") }
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/telefone/status/${phone}`, { headers });
            setStatus(response.data.status);
            if (response.data.sessionData?.qr_code) {
                setQrCode(response.data.sessionData.qr_code);
            }
        } catch (error) {
            console.error("Erro ao verificar status:", error);
        }
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box className="whatsapp-modal">
                <div className="modal-header">
                    <h2>Conectar ao WhatsApp</h2>
                    <button className="close-button" onClick={() => setOpen(false)}>
                        &times;
                    </button>
                </div>

                <div className="modal-body">
                    <input
                        type="text"
                        placeholder="Digite seu número de WhatsApp"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="phone-input"
                    />
                    <button onClick={startSession} className="generate-button">
                        Gerar QR Code
                    </button>

                    {qrCode && (
                        <div className="qr-section">
                            <h3>Escaneie o QR Code:</h3>
                            <img src={qrCode} alt="QR Code" className="qr-code" />
                        </div>
                    )}

                    <button onClick={checkStatus} className="status-button">
                        Verificar Status
                    </button>
                    {status && <p className="status-text">Status: {status}</p>}
                </div>

                <div className="modal-footer">
                    <button onClick={() => setOpen(false)} className="cancel-button">Fechar</button>
                </div>
            </Box>
        </Modal>
    );
};

export default WhatsAppModal;
