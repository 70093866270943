import React, { useEffect, useState } from "react";
import "./OrdersList.scss";
import { getOrdersByMenu } from "../../../../components/helpers/Api";
import { Load } from "../../../../components/widgets/spinner/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import { getCliente } from "../../../../services/AuthService";
import IdentifyUserModal from "../../../../components/partials/modals/IdentifyUserModal";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_API_URL);

const OrdersList = () => {
  const [data, setData] = useState([]);
  const [org, setOrg] = useState(null);
  const [loading, setLoading] = useState(false);
  const { empresa } = useParams();
  const [openIdentifyUserModal, setOpenIdentifyUserModal] = useState(false);
  const [editModal, setEditModal] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const navigate = useNavigate();

  const fetchCodOrg = async () => {
    try {
      const codOrg = await getCliente(empresa);
      setOrg(codOrg);
      return codOrg;
    } catch (error) {
      console.error("Failed to fetch codOrg:", error);
    }
  };


  // useEffect(() => {
  //   fetchCodOrg();
  // }, []);
  useEffect(() => {
    const consumerUser = JSON.parse(localStorage.getItem("consumerUser"));
    if (!consumerUser || !consumerUser.telefone) {
      setOpenIdentifyUserModal(true);
    } else {
      handleGetOrders(true);
    }
  }, []);

  function handleGetOrders(update) {
    if (!update) {
      setLoading(true);
    }
    fetchCodOrg().then((cod_org) => {
      const consumerUser = JSON.parse(localStorage.getItem("consumerUser"));

      if (!consumerUser || !consumerUser.telefone) {
        setOpenIdentifyUserModal(true);
        setLoading(false);
        return;
      }

      getOrdersByMenu(
        consumerUser.telefone,
        consumerUser.id,
        cod_org
      )
        .then((res) => {
          setData(res.data.data.rows);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }


  // useEffect(() => {
  //   socket.on(
  //     `update_status${JSON.parse(localStorage.getItem("consumerUser")).telefone}${localStorage.getItem("cod_org")}`,
  //     () => {
  //       handleGetOrders(true);
  //     }
  //   );

  //   return () => {
  //     socket.off(`update_status${JSON.parse(localStorage.getItem("consumerUser")).telefone}${localStorage.getItem("cod_org")}`);
  //   };
  // }, []);

  function handleOrderViewDetail(cod_pedidomesa) {
    if (cod_pedidomesa) {
      navigate(`/menu/${empresa}/pedido/${cod_pedidomesa}`);
    }
  }

  function handleRepeatOrder(event, orderId) {
    event.stopPropagation();
    // Implement repeat order functionality
    console.log("Repeat order:", orderId);
  }

  // function handleGetOrders(update) {
  //   if (!update) {
  //     setLoading(true);
  //   }
  //   fetchCodOrg().then((cod_org) => {
  //     if (!(localStorage.getItem("consumerUser"))?.telefone) {

  //     }

  //     getOrdersByMenu(
  //       JSON.parse(localStorage.getItem("consumerUser"))?.telefone,
  //       JSON.parse(localStorage.getItem("consumerUser"))?.id,
  //       cod_org
  //     )
  //       .then((res) => {
  //         setData(res.data.data.rows);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   })
  // }

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} às ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  return (
    <>
      <div className="orders-container">
        <header className="orders-header">
          <button
            className="back-button"
            onClick={() => navigate(`/menu/${empresa}`)}
          >
            {/* <FontAwesomeIcon icon={faArrowLeft} /> */}
          </button>
          <h1>Meus pedidos</h1>
        </header>

        {loading ? (
          <div className="loading-container">
            <Load />
          </div>
        ) : (
          <div className="orders-list">
            {data.length < 1 ? (
              <div className="no-orders">Não há pedidos</div>
            ) : (
              data.map((item) => (
                <div key={item.numero_pedidomesa} className="order-card">
                  <div className="order-header">
                    <div className="order-title">
                      <h2>Pedido #{item.numero_pedidomesa}</h2>
                      <span className="order-date">
                        Em {formatDate(item.createdAt)}
                      </span>
                    </div>
                    <div className="status-badge">
                      {item.situacao === "finalizado" ? "Finalizado" : item.situacao}
                    </div>
                  </div>

                  <div className="order-items">
                    {item.tb_ipedidomesas && item.tb_ipedidomesas.length > 0 ? (

                      item.tb_ipedidomesas.map((produto, index) => (
                        <div key={index} className="item">
                          <span>{produto.quantidade}x {produto.descricao}</span>
                        </div>
                      ))
                    ) : (
                      <div className="item">
                        <span>1x Item do pedido</span>
                      </div>
                    )}
                  </div>

                  <div className="order-total">
                    <strong>R$ {item.valor_total.toFixed(2).replace('.', ',')}</strong>
                  </div>

                  <div className="order-actions">
                    <button
                      className="details-button"
                      onClick={() => handleOrderViewDetail(item.numero_pedidomesa)}
                    >
                      Detalhes do pedido
                    </button>

                    {/* <button
                    className="repeat-button"
                    onClick={(e) => handleRepeatOrder(e, item.numero_pedidomesa)}
                  >
                    Repetir pedido
                  </button> */}
                  </div>
                </div>
              ))
            )}
          </div>
        )}

        <nav className="bottom-nav">
          <button className="nav-item" onClick={() => navigate(`/menu/${empresa}`)}>
            <i className="home-icon"></i>
            <span>Início</span>
          </button>
          <button className="nav-item active">
            <i className="orders-icon"></i>
            <span>Pedidos</span>
          </button>
          <button className="nav-item" onClick={() => navigate(`/menu/${empresa}/carrinho`)}>
            <i className="cart-icon"></i>
            <span>Carrinho</span>
          </button>
        </nav>
      </div>
      <IdentifyUserModal
        open={openIdentifyUserModal}
        edit={"false"}
        editModalFalse={() => setEditModal("")}
        setModalIdentifyFalse={() => {
          setOpenIdentifyUserModal(false);
          handleGetOrders(true); // chama novamente após fechar a modal
        }}
      />

    </>
  );
};

export default OrdersList;